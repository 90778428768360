// Vue plugin.
const MyPlugins= {
	install(Vue,options){
		Vue.prototype.ruleRequired= v=>!!v||'必填或者必选的'
		Vue.prototype.KUAISHOU_NO= KUAISHOU_NO
		Vue.prototype.JDQR_NO= JDQR_NO
		Vue.prototype.CNMOBILE_NO= CNMOBILE_NO
		Vue.prototype.JDUNION_NO= JDUNION_NO
		Vue.prototype.$f= function(fields){ //filterHeaders
			for (var i = fields.length; i--;){
				var header=fields[i]
				if(header.norole && this.role == header.norole)
					fields.splice(i,1)
				if(header.role && this.role!=header.role)
					fields.splice(i,1)
				if(header.project_no && this.project_no!=header.project_no)
					fields.splice(i,1)
				if(header.project_id && this.project_id!=header.project_id)
					fields.splice(i,1)
				if(header.ff){
					if(this.ff()==false )
						fields.splice(i,1)
				}
			}
			return fields
		}
		Vue.prototype.$reload=function(){
			this.options.page=1
			this.loadData()
		}
		Vue.prototype.$fh= function(){ //filterHeaders
			for(var i in this.headers){
				var header=this.headers[i]
				if(header.norole && this.role == header.norole)
					delete this.headers[i]
				if(header.role && this.role!=header.role)
					delete this.headers[i]
				if(header.project_no && this.project_no!=header.project_no)
					delete this.headers[i]
				if(header.project_id && this.project_id!=header.project_id)
					delete this.headers[i]
				if(header.ff){
					if(this.ff()==false )
						delete this.headers[i]
				}
				if(header.f){
//					header.filter=this['filter_'+header.value.replace('_id','')]
					var field=header.value
					header.filter=value=>{
						if(this[field]==null )return true
						return value==this[field]
					}
				}
			}
			return true
		}
	}
};

export default MyPlugins;
