<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {
    
  },
  beforeMount() {
	 
    },

};
</script>
<style>
th {
  font-size: 0.85rem !important;
}
.upload{
}
.upload img{
    height:10rem;
    margin-left:1rem;
    cursor:pointer;
}
.upload input{
    display:none;
}
.upload label{
    display:flex;
    font-size:1.1rem;
    align-items:center;
}
.upload .v-input__icon{
	width:auto !important;
	font-size:14px;
}
.upload .v-icon.v-icon{
	font-size:14px;
}
td img{
    height:5rem;
}
.v-toolbar__content{
	align-items:center;
}
#login img{
	width:40px;
}
.detail-list{
	border-bottom: 1px solid #eee;
}
.v-form .upload{
	border-bottom:1px solid currentColor;
	padding-bottom:10px;
}
.right{
	float:right;
}
.v-sheet{
	min-width:250px;
}
th{border-top:1px solid #ddd}
th,td{
	border-right:1px solid #ddd;
	text-align:center;
}
th.text-start{
	text-align:center !important;
}
tr>th:last-child,tr>td:last-child{
	border-right:none;
}
th,td{
}
.data-filter{
	padding-top:6px;
}
pre{
	max-width:380px;
	text-align:left;
	white-space:pre-wrap;
}
.v-application p{
	margin-bottom:2px;
}
.search-input{
	width:70%;
	display:inline-block !important;
}
.search-key{
	width:30%;
	display:inline-block !important;
}
pre{
	text-align:left;
}
</style>
