import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(Router,VueAxios,axios);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "starterpage",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "StarterPage", path: "starterpage", component: () => import("@/views/StarterPage"), },
        { name: "password", path: "password", component: () => import("@/views/password"), },
        { name: "message", path: "message", component: () => import("@/views/message"), },
		  { name: "feedback", path: "feedback", component: () => import("@/views/feedback/list"), },
        { name: "douyin", path: "douyin", component: () => import("@/views/douyin"), },
      ],
    },
     {
      path: "/agent",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "agentlist", path: "list", component: () => import("@/views/agent/list"), },
        { name: "ag", path: "child", component: () => import("@/views/agent/list"), },
        { name: "ap", path: "parent", component: () => import("@/views/agent/parent"), },
        { name: "parents", path: "parents", component: () => import("@/views/agent/parents"), },
      ],
    },
     {
      path: "/notify",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "notify", path: "list", component: () => import("@/views/message/list"), },
      ],
    },
     {
      path: "/home",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "announce", path: "announce", component: () => import("@/views/home/announce"), },
        { name: "notice", path: "notice", component: () => import("@/views/home/notice"), },
        { name: "banner", path: "banner", component: () => import("@/views/home/banner"), },
      ],
    },
     {
      path: "/setting",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "upgrade", path: "upgrade", component: () => import("@/views/setting/upgrade"), },
        { name: "qr2", path: "qr2", component: () => import("@/views/setting/qr2"), },
        { name: "jdshop", path: "jdshop", component: () => import("@/views/setting/jdshop"), },
      ],
    },
     {
      path: "/jdunion",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "jdunion", path: "list", component: () => import("@/views/jdunion/list"), },
      ],
    },
     {
      path: "/taobaomaicai",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "taobaomaicai", path: "list", component: () => import("@/views/taobaomaicai/list"), },
        { name: "taobaoid", path: "taobaoid", component: () => import("@/views/taobaomaicai/taobaoid"), },
      ],
    },
     {
      path: "/meituan",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "meituan", path: "list", component: () => import("@/views/meituan/list"), },
        { name: "meituanwxa", path: "meituanwxa", component: () => import("@/views/meituan/wxa"), },
      ],
    },
  {
      path: "/jdstore",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "invite", path: "invite", component: () => import("@/views/jdstore/invite"), },
		{ name: "jdstore", path: "list/:project_id", component: () => import("@/views/jdstore/list"), },
        { name: "jdbuy", path: "jdbuy", component: () => import("@/views/jdstore/jdbuy"), },
      ],
    },
     {
      path: "/etc",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "order", path: "order/:project_id", component: () => import("@/views/etc/order"), },
        { name: "refund", path: "refund/:project_id", component: () => import("@/views/etc/refund"), },
      ],
    },
     {
      path: "/account",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "accountlist", path: "list", component: () => import("@/views/account/list"), },
        { name: "withdraw", path: "withdraw", component: () => import("@/views/account/withdraw"), },
        { name: "pay", path: "pay", component: () => import("@/views/account/pay"), },
      ],
    },

     {
      path: "/project",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        { name: "upload", path: "upload", component: () => import("@/views/project/upload"), },
        { name: "list", path: "list", component: () => import("@/views/project/home"), },
        { name: "price", path: "price", component: () => import("@/views/project/price"), },
        { name: "pricing", path: "pricing", component: () => import("@/views/project/pricing"), },
        { name: "childprice", path: "childprice", component: () => import("@/views/project/childprice"), },
        { name: "qrcode", path: "qrcode", component: () => import("@/views/project/qrcode"), },
        { name: "orders", path: "orders", component: () => import("@/views/project/orders"), },
        { name: "stat", path: "stat", component: () => import("@/views/project/stat"), },
        { name: "statmore", path: "statmore", component: () => import("@/views/project/statmore"), },
        { name: "statchild", path: "statchild", component: () => import("@/views/project/statchild"), },
        { name: "edit", path: "edit", component: () => import("@/views/project/edit"), },
        { name: "checkin", path: "checkin", component: () => import("@/views/project/checkin"), },
        { name: "kuaishou", path: "kuaishou", component: () => import("@/views/project/kuaishou"), },
        { name: "gome", path: "gome", component: () => import("@/views/project/gome"), },
        { name: "t3", path: "t3", component: () => import("@/views/project/t3"), },
        { name: "review", path: "review", component: () => import("@/views/project/review"), },
        { name: "jd", path: "jd", component: () => import("@/views/project/jd"), },
        { name: "batprice", path: "batprice", component: () => import("@/views/project/batprice"), },
        { name: "qrconf", path: "qrconf", component: () => import("@/views/project/qrconf"), },
        { name: "qrcoding", path: "qrcoding/:project_id", component: () => import("@/views/project/qrcoding"), },
        { name: "gentb", path: "gentb", component: () => import("@/views/project/gentb"), },
        { name: "pin", path: "pin", component: () => import("@/views/project/pin"), },
        { name: "code", path: "code", component: () => import("@/views/project/code"), },
		  { name: "code_list", path: "code_list/:project_id", component: () => import("@/views/project/code_list"), },
      ],
    },

    {
      path: "/auth",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "login",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "reg",
          component: () => import("@/views/authentication/reg"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
