import {uni} from './uni'
const qs = require('qs');
export const API_ROOT = process.env.NODE_ENV == 'production'?'/':'http://uniend.ai/'
global.API_ROOT= API_ROOT
global.JDQR_NO='P210125001'
global.KUAISHOU_NO='P21030401'
global.CNMOBILE_NO='P21022101'
global.JDUNION_NO='P21041501'
export async function get(path, params = {}) {
	this.$loading(true)
	var sid = localStorage.getItem('sid')
	if (sid) params.sid = sid
	const response = await uni.request({
		method:'get',
		url: API_ROOT + path,
		params: params,
		withCredentials: true
	})
	if(response.data.code==403){
		location='/#/auth/login'
	}
	this.$loading(false)
	return response.data
	
}
export async function request(url, params = {}) {
	var [error, res] = await uni.request({
		url: url,
		data: params,
	})
	return res.data
}
export function toast(title){
	return uni.showToast({
		title:title,
		icon:'none'
	})
}
export function filter_agent(value,item){
	console.log(value)
	console.log(item)
	if (!this.agent_id) {
		return true;
	}
	return value == this.agent_id;
}

export function isShow(mrole){
	const role = this.user.role
	if(!mrole) return true;
	if(mrole=='noadmin' && role=='admin') return false;
	if(role=='admin')return true;
	if(mrole=='admin')return false;
	if(mrole=='core_agent' && role=='agent') return false;
	return true;
}
Date.prototype.Format = function(fmt) { //author: meizz
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[
			k]).substr(("" + o[k]).length)));
	return fmt;
}
export async function post(path, params = {}) {
	this.$loading(true)
	var sid = localStorage.getItem('sid')
	var query = sid ? {sid:sid} :{}
	const res = await uni.request({
		url: API_ROOT + path,
		params: query,
		data: qs.stringify(params),
		method: 'POST',
		header: {
			'content-type': 'application/x-www-form-urlencoded' //自定义请求头信息
		},
		withCredentials: true
	})
	this.$loading(false)
	return res.data
}
export async function qiniuUpload(token,file) {
    var key='uniend_'+(new Date()).getTime()+'.png'
    let formData = new FormData()
    var data= {
            name: 'img',
            file:file,
            chunk:0,
            chunks:1,
            key:key,
            token:token
    }
    for(var i in data){
        formData.append(i,data[i])
    }
	const res = await uni.request.post('http://up-z2.qiniu.com', formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        },
	    })
	return res.data
}
export async function upload(path, formData) {
	this.$loading(true)
	var sid = localStorage.getItem('sid')
	path=path.indexOf('?')>-1?path+'&sid='+sid:path+'?sid='+sid
	path=API_ROOT+path
	const res = await uni.request.post(path, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        },
			withCredentials: true
	    })
	this.$loading(false)
	return res.data
}
