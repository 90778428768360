import { render, staticRenderFns } from "./BaseBreadcrumb.vue?vue&type=template&id=8b58e752&"
import script from "./BaseBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./BaseBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./BaseBreadcrumb.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBreadcrumbs,VCol,VIcon,VRow,VSpacer,VToolbar,VToolbarTitle})
