import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import {get,post,upload,qiniuUpload,request,isShow,logined,toast,API_ROOT} from './utils'
Vue.use(VueAxios,axios);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
import JsonExcel from "vue-json-excel";
 
Vue.component("downloadExcel", JsonExcel);
import MyPlugins from "./plugins/myplugins";
Vue.use(MyPlugins)
import VueLoading from 'vuejs-loading-plugin'

// using default options
Vue.use(VueLoading)
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
	timeout:2000,
	position: 'top-center',
	hideProgressBar: true
};

Vue.use(Toast, options);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.prototype.get=get
Vue.prototype.post=post
Vue.prototype.upload=upload
Vue.prototype.qiniuUpload=qiniuUpload
Vue.prototype.isShow=isShow
//global.user=localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{}
Vue.prototype.__defineGetter__('user', function() { return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):null});
Vue.prototype.__defineGetter__('role', function() { return this.user.role });

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

